
import {
  defineComponent,
  computed,
  ref,
  onMounted,
  watch,
  onUnmounted
} from 'vue';
import { useStore } from 'vuex';
import { lostelkUrl } from '../global';
import Header from '../components/header/HeaderBox.vue';
import Sidebar from '../components/sidebar/SidebarBox.vue';
import axios from 'axios';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'UserHomePage',
  components: {
    Header,
    Sidebar
  },
  props: {
    cardColumn: Number,
    UserId: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const route = useRoute();
    const store = useStore();
    // 获取页面展示列的数量
    const cardColumnSize = computed(() => props.cardColumn);
    // 获取当前登陆的用户ID
    const userId = computed(() => store.state.user.id);
    // 获取当前个人页的用户ID
    const UserIdProp = computed(() => Number(props.UserId));

    /**
     * 判断是否登录,用于header组件
     */
    const loginJudge = computed(() => {
      return store.state.user;
    });

    /**
     * 获取指定用户的信息
     */
    const userData = ref();
    try {
      axios.get(`${lostelkUrl}/users/${UserIdProp.value}`).then(data => {
        userData.value = data.data;
      });
    } catch (error) {
      // console.log(error);
    }

    /**
     * 获取指定用户发表的内容列表
     */
    const userPhotosCardlist = computed(() => store.state.userPhotosCardList);
    const userPhotosCardTotalCount = computed(
      () => store.state.userPhotosCardTotalCount
    );
    const fileMetadata = ref();
    store.dispatch('getUserPhotosCardList', UserIdProp.value).then(res => {
      // 请求获取图像文件元信息
      if (!res.data[0]) return;
      store.dispatch('getFileMetadata', res.data[0].file.id).then(data => {
        if (data.mainColor) {
          const colorBlock = document.getElementById(
            'color-block-container'
          ) as HTMLElement;
          colorBlock.style.backgroundColor = data.mainColor;
        }
      });
    });

    /**
     * 在内容列表发生改变时,请求获取图像文件元信息
     */
    watch(
      () => userPhotosCardlist.value,
      () => {
        // 请求获取图像文件元信息
        if (!userPhotosCardlist.value[0]) return;
        store
          .dispatch('getFileMetadata', userPhotosCardlist.value[0].file.id)
          .then(data => {
            if (data.mainColor) {
              const colorBlock = document.getElementById(
                'color-block-container'
              ) as HTMLElement;
              colorBlock.style.backgroundColor = data.mainColor;
            }
          });
      }
    );

    /**
     * 获取指定用户喜欢的内容列表
     */
    const userLikeCardlist = computed(() => store.state.userLikeCardList);
    const userLikeCardTotalCount = computed(
      () => store.state.userLikeCardTotalCount
    );
    store.dispatch('getUserLikeCardList', UserIdProp.value);

    /**
     * 监听 路由上的UserId参数是否发生改变, 如果发生改变 则 重新加载新的数据
     */
    watch(
      () => route.params.UserId,
      () => {
        if (route.params.UserId) {
          try {
            store.dispatch(
              'getUserPhotosCardList',
              Number(route.params.UserId)
            );
            store.dispatch('getUserLikeCardList', Number(route.params.UserId));
            axios
              .get(`${lostelkUrl}/users/${Number(route.params.UserId)}`)
              .then(data => {
                userData.value = data.data;
              });
          } catch (error) {
            // console.log(error);
          }
          // 恢复到顶部
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0;
        }
      }
    );

    onMounted(() => {
      // 恢复到顶部
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    });

    onUnmounted(() => {
      store.commit('getUserPhotosCardList', []);
      store.commit('getUserLikeCardList', []);
      store.commit('getHomePageCardTotalCount', 0);
      store.commit('getUserLikeCardTotalCount', 0);
    });

    return {
      loginJudge,
      userPhotosCardlist,
      userPhotosCardTotalCount,
      cardColumnSize,
      UserIdProp,
      lostelkUrl,
      userData,
      userId,
      userLikeCardTotalCount,
      userLikeCardlist,
      fileMetadata
    };
  }
});
